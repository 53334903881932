import clsx from 'clsx';
import { CalculatorIcon, ChartCandlestick, ChartCandlestickIcon, ChartSpline, ClipboardList, Newspaper, SchoolIcon } from 'lucide-react';
import React from 'react';

function WidgetsDocsPage() {
  const listCards = [
    {id: 1, icon: <ChartCandlestickIcon height={40} width={40}/>, title: 'Charts', url: '#/widgets/kgx', text: 'Integrate anywhere and everywhere.'},
    {id: 2, icon: <ClipboardList height={40} width={40}/>, title: 'Watchlists', url: '#/widgets/kgx', text: 'See everything at a glance.'},
    {id: 3, icon: <SchoolIcon height={40} width={40}/>, title: 'Tutorial', url: '#/tutorial', text: 'Integrate anywhere and everywhere.'},
    {id: 4, icon: <Newspaper height={40} width={40}/>, title: 'News', url: '#/widgets/news', text: 'Integrate anywhere and everywhere.'},
    {id: 5, icon: <ChartSpline height={40} width={40}/>, title: 'Historical Data', url:'#/widgets/branded_metal_performance', text: 'Integrate anywhere and everywhere.'},
    {id: 6, icon: <CalculatorIcon height={40} width={40}/>, title: 'Calculator', url: '/#/widgets/price_calculator', text: 'Integrate anywhere and everywhere.'}
  ]; 
  return (
    <div className='pt-[40px] pb-[40px] w-full flex flex-col items-start lg:pl-[40px]'>
      <div className='text-[30px] font-bold pb-4'>Widgets</div>
      <div className='text-[16px] lg:w-2/3'>
        Welcome to the Kitko Widgets collection, where financial magic meets the simplicity of copy-paste. These powerful tools will supercharge your website with real-time financial data and stunning charts. Say goodbye to dull numbers and hello to dynamic visualizations that'll make your visitors go "Wow!"
      </div>

      <div className='text-[20px] font-bold pt-4'>Ready to explore?</div>
      <div className='text-[16px]'>Just click on a category below to get started.</div>

      <div className='w-full lg:w-2/3 grid grid-cols-1 lg:grid-cols-2 gap-4 pt-6'>
         {listCards.map((item, idx) => <div key={item.id} className={'border border-gray-300 pl-6 py-6 rounded-lg'}>
               <div>{item.icon}</div>
               <div className='pt-4 text-[16px] font-bold'>{item.title}</div>  
               <div className='text-[14px] mb-4'>{item.text}</div>
               <a className='px-2 py-2 bg-gray-300 text-[14px] rounded-lg' href={item.url}>See widgets</a>
         </div>)}   
      </div>
    </div>
  );
}

export default WidgetsDocsPage;