import clsx from 'clsx';
import { ChevronDown, ChevronRight } from 'lucide-react';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

interface PropsI {
  option: MenuOption;
}

interface OptionProps {
  item: SubOption;
  index: number; 
}

const Option = ({ item, index }: OptionProps) => {
  const [openSub, setOpenSub] = useState(index === 0);
  const isSubOptionsExist = item.subOptions.length > 0; 
  const navigate = useNavigate(); 
  return (
    <div>
      <div className='flex justify-between items-center py-1 px-2 hover:bg-gray-200'>
        <div className='font-medium text-[14px]'>{item.title}</div>
        {isSubOptionsExist && <div
          className='cursor-pointer p-2 hover:bg-gray-300 rounded-md'
          onClick={() => setOpenSub((prev) => !prev)}
        >
          {!openSub ? <ChevronRight size={14} /> : <ChevronDown size={14} />}
        </div>}
      </div>
      {openSub && item.subOptions.length ? (
        <nav className=''>
          <ul className='space-y-2 text-[14px]'>
            {item.subOptions.map((el) => (
              <li key={el.path} className='hover:bg-gray-200 py-1.5 pl-4 cursor-pointer' onClick={() => navigate(el.path)}>
                <NavLink
                  // onClick={handleClose}
                  className={({ isActive }) => (isActive ? 'font-normal' : '')}
                  to={el.path}
                >
                  {el.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      ) : ''}
    </div>
  );
};

function MenuItem({ option }: PropsI) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(true);
  const isOptionsExist = option.options.length > 0; 
  const handleClick = () => {
     if(option.isExternal) {
      window.open(option.path, '_blank')
     } else {
      navigate(option.path ?? ''); 
     }
  }
  return (
    <div className={clsx('w-full')}>
      <div className='flex justify-between items-center px-2 hover:bg-gray-200'>
        <div onClick={handleClick} className={clsx('font-semibold text-[16px] flex items-center h-[40px]', {
          'cursor-pointer': !isOptionsExist
        })}>
          {option.title}
         </div>
        {isOptionsExist && <div
          className='cursor-pointer p-2 hover:bg-gray-300 rounded-md'
          onClick={() => setOpen((prev) => !prev)}
        >
          {!open ? <ChevronRight size={14} /> : <ChevronDown size={14} />}
        </div>}
      </div>
      {open &&
          option.options.length ?
          option.options.map((item, idx) => <Option key={idx} item={item} index={idx}/>) : ''}
    </div>
  );
}

export default MenuItem;
