import { Path } from "common/constants";

export const listMenu: MenuOption[] = [
    {
        title: 'Widgets', 
        options: [
            {
                title: 'Kitco Gold Index',
                subOptions: [
                    {
                        title: 'KGX Widget',
                        path: Path.KGX
                    },
                    {
                        title:'KGX Mini 2',
                        path: Path.METAL_BID          
                    },
                    {
                        title: 'KGX Table Format',
                        path: Path.COMODITY
                    },
                    {
                        title: 'Gold Bid',
                        path: Path.GOLD_BID
                    }
                ]
            }, 
            {
                title: 'Single Items', 
                subOptions: [
                    {
                        title: 'Small Gold',
                        path: Path.SMALL_GOLD_PRICE
                    },
                    {
                        title: 'Spot Price',
                        path: Path.SPOT_PRICE
                    },
                    {
                        title: 'London Fix',
                        path: Path.LONDON_FIX
                    },
                ], 
            }, 
            {
                title: 'Tables',
                subOptions: [
                    {
                        title: 'KGX Table Format',
                        path: Path.COMODITY
                    },
                    {
                        title: 'Cross Rates Widget',
                        path: Path.CROSS_RATES
                    },
                    {
                        title: 'Metals Price Performance',
                        path: Path.METAL_PRICE_PERFORMANCE
                    }
                ], 
            },
            {
                title: 'Charts',
                subOptions: [
                    {
                        title: 'Gold Chart Widget',
                        path: Path.GOLD_CHART
                    },
                    {
                        title: 'Precious Metals',
                        path: Path.PRECIOUS_METALS
                    }
                ], 
            },
            {
                title: 'Historical Data',
                subOptions: [
                    {
                        title: 'Branded Metal Performance',
                        path: Path.BRANDED_METAL_PERFORMANCE
                    },
                    {
                        title: 'Metals Price Performance',
                        path: Path.METAL_PRICE_PERFORMANCE
                    }
                ], 
            },
            {
                title: 'News',
                subOptions: [
                    {
                        title: 'News Widget',
                        path: Path.NEWS
                    }
                ], 
            },
            {
                title: 'Calculator',
                subOptions: [
                    {
                        title: 'Price Calculator',
                        path: Path.PRICE_CALCULATOR
                    }
                ], 
            },
            {
                title: 'Tickers',
                subOptions: [
                    {
                        title: 'Precious Metals Ticker',
                        path: Path.FINANCIAL_TICKER
                    }
                ], 
            }
        ]
    },
    {
        title: 'Tutorial',
        options: [], 
        path: `/${Path.TUTORIAL}`,  
    },
    {
        title: 'Contact us',
        options: [],
        path: `/${Path.CONTACT_US}`, 
    }, 
    {
        title: 'About Kitco',
        options: [], 
        path: 'https://corp.kitco.com/',
        isExternal: true
    }
]