import React, { useState, useEffect } from 'react'
import { RefreshCcw, Clock, Sun, Moon, Info } from 'lucide-react'
import { Instrument, VisualizationType, DataObject, ChartPeriod } from './types'
import { instruments, visualizationTypes, chartPeriods } from './constants'
import { getAnalysis, splitText, fetchDataFromAPI } from './utils'
import BubbleVisualization from './BubbleVisualization'
import TextView from './TextView'
import PieChartVisualization from './PieChartVisualization'
import ChartVisualization from './ChartVisualization'
import InfoPopup from './InfoPopup'
import { useWindowSize } from 'usehooks-ts'

interface FinancialWidgetProps {
  config: WidgetConfig, 
}

const FinancialWidget: React.FC<FinancialWidgetProps> = ({config}) => {
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument>(instruments[0])
  const [data, setData] = useState<DataObject | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isInfoOpen, setIsInfoOpen] = useState(false)
  const [containerWidth, setContainerWidth] = useState<any>('600'); 
  const [selectedChartPeriod, setSelectedChartPeriod] = useState(chartPeriods[0])
  const [selectedVisualization, setSelectedVisualization] = useState<VisualizationType>(visualizationTypes[0])

  const {width} = useWindowSize();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const newData = await fetchDataFromAPI(selectedInstrument);
      setData(newData)
      setIsLoading(false)
    }

    fetchData()
    const interval = setInterval(fetchData, 10000)

    return () => clearInterval(interval)
  }, [selectedInstrument])

  useEffect(() => {
    setIsDarkMode(config.colorTheme === 'dark'); 
    setContainerWidth(config.width)
  }, [config]); 

/*   if (isLoading || !data) {
    return <div>Loading...</div>
  } */

  const changeColor = (data && data.totalChange >= 0) ? 'text-green-500 dark:text-green-400' : 'text-red-500 dark:text-red-400'

  const renderChartPeriodButtons = () => {
    return chartPeriods.map((period: ChartPeriod) => {
      const { firstHalf, secondHalf } = splitText(period.name)
      return (
        <button
          key={period.id}
          onClick={() => setSelectedChartPeriod(period)}
          className={`
            flex-grow
            min-w-[50px]
            px-1 py-2
            mx-0.5 sm:mx-1
            mb-1 sm:mb-0
            text-xs sm:text-sm font-medium
            ${selectedChartPeriod.id === period.id
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }
            rounded-lg
            text-center
            h-14 sm:h-auto
            flex flex-col sm:flex-row justify-center items-center
          `}
        >
          {firstHalf}
          <br className="sm:hidden" />
          <span className="hidden sm:inline">&nbsp;</span>
          {secondHalf}
        </button>
      )
    })
  }

  const renderVisualization = (data: any) => {
    switch (selectedVisualization.id) {
      case 'text':
        return <TextView data={data} isDarkMode={isDarkMode} />
      case 'bubbles':
        return <BubbleVisualization data={data} selectedInstrument={selectedInstrument} isDarkMode={isDarkMode} />
      case 'pieCharts':
        return <PieChartVisualization data={data} selectedInstrument={selectedInstrument} isDarkMode={isDarkMode} />
      case 'charts':
        return (
          <div>
            <div className="flex overflow-x-auto mb-4">
              {renderChartPeriodButtons()}
            </div>
            <ChartVisualization
              instrument={selectedInstrument}
              period={selectedChartPeriod}
              isDarkMode={isDarkMode}
            />
          </div>
        );
      default:
        return <TextView data={data} isDarkMode={isDarkMode} />
    }
  }

  const renderInstrumentOptions = () => {
    const groupedInstruments = {
      'Precious Metals': instruments.filter(i => i.apiType === 'precious'),
      'Base Metals': instruments.filter(i => i.apiType === 'base'),
      'Energy': instruments.filter(i => i.apiType === 'oil')
    }

    return (
      <>
        {Object.entries(groupedInstruments).map(([group, groupInstruments]) => (
          <optgroup label={group} key={group}>
            {groupInstruments.map(instrument => (
              <option key={instrument.id} value={instrument.id}>
                {instrument.name}
              </option>
            ))}
          </optgroup>
        ))}
      </>
    )
  }

  return (
    <div className={`p-4 lg:p-4 border rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800 text-white' : config?.isTransparent ? 'bg-opacity-0' : 'bg-white text-gray-800'}`} style={{width: width > 992 ? `${containerWidth}` : '100%'}}>
      <h2 className="text-lg font-bold text-center mb-2">KGX</h2>
      <div className="flex justify-center items-center mb-4 lg:flex-wrap">
        <select
          value={selectedInstrument.id}
          onChange={(e) => {
            const found = instruments.find(i => i.id === e.target.value)
            if (found) {
              setSelectedInstrument(found)
            }
          }}
          className="mr-2 lg:mr-2 p-1 my-2 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
        >
          {renderInstrumentOptions()}
        </select>

        <select
          value={selectedVisualization.id}
          onChange={(e) => {
            const found = visualizationTypes.find(v => v.id === e.target.value)
            if (found) {
              setSelectedVisualization(found)
            }
          }}
          className="mr-2 lg:mr-2 p-1 text-[14px] rounded border bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
        >
          {visualizationTypes.map(vizType => (
            <option key={vizType.id} value={vizType.id}>{vizType.name}</option>
          ))}
        </select>
        <div className="hidden sm:flex items-center">
          <Clock size={16} className="mr-2" />
          <span className="text-[14px] mr-2 lg:mr-2">{data?.timestamp}</span>
        </div>
        <RefreshCcw size={16} className={`mr-2 lg:mr-2 ${isLoading ? 'animate-spin' : ''}`} />
        <button
          onClick={() => setIsDarkMode(!isDarkMode)}
          className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors mr-2"
        >
          {isDarkMode ? <Sun size={14} /> : <Moon size={14} />}
        </button>
        <button
          onClick={() => setIsInfoOpen(true)}
          className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
        >
          <Info size={14} />
        </button>
      </div>

      {data && <div className="text-center mb-2">
        <div className="text-[18px] font-bold">${data.price.toFixed(2)} <span className="text-sm font-normal">(Bid)</span></div>
        <div className={`text-[16px] font-semibold ${changeColor}`}>
          {data.totalChange >= 0 ? '+' : ''}{data.totalChange.toFixed(2)} ({data.totalChangePercent >= 0 ? '+' : ''}{data.totalChangePercent.toFixed(2)}%)
        </div>
      </div>}

      {/* This will only show on mobile */}
      <div className="sm:hidden text-center mb-2">
        <div className="flex items-center justify-center">
          <Clock size={16} className="mr-2" />
          <span className="text-sm">{data?.timestamp}</span>
        </div>
      </div>

      {data && <div className="mb-2 text-center text-[16px] font-semibold">
        Did {selectedInstrument.name} really go {data.totalChange >= 0 ? 'up' : 'down'} ${Math.abs(data.totalChange).toFixed(2)}?
      </div>}

      {data && <div className={`mb-4 text-[14px] p-2 rounded ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
        <div dangerouslySetInnerHTML={{ __html: getAnalysis(data) }} />
      </div>}

      {data && renderVisualization(data)}

      <div className="mt-2 text-center text-sm">
        <a
          href="https://www.kitco.com"
          target="_blank"
          rel="noopener noreferrer"
          className={`${isDarkMode ? 'text-white hover:text-gray-300' : 'text-blue-500 hover:text-blue-700'}`}
        >
          KGX by: kitco.com
        </a>
      </div>

      <InfoPopup isOpen={isInfoOpen} onClose={() => setIsInfoOpen(false)} />
    </div>
  )
}

export default FinancialWidget

